@media screen and (max-width: 756px) {
    .usage {
        padding-top: get-vw(250px);
        .title {
            font-size: get-vw(150px);
            margin-bottom: 15px; }
        p {
            padding-bottom: 15px;
            &:last-child {
                margin-top: 20px; } }
        p, span {
            width: 80%;
            font-size: get-vw(80px); }
        .star {
            font-size: 4.04167vw; } } }
