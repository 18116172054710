@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,900|Rubik:300,400,500&subset=cyrillic");
@import url(/app/libs/jq-accordion/jq-accordion.css);
@import url(/app/libs/tooltipster/dist/css/tooltipster.bundle.min.css);
@import url(/app/libs/tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-light.min.css);
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: border-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.overview .container .left-part .button-box .btn:first-child, .overview .container .right-part .desktop-img, .description li, .instruction-wrapper, .storage .btn, .storage .btn--instr {
  position: relative; }

.overview h1, .description .title, .instruction .title, .indication .title, .usage .title, .storage .title {
  background: #e45d5d;
  background: -moz-linear-gradient(left, #e45d5d 0%, #eb9e38 100%);
  background: -webkit-linear-gradient(left, #e45d5d 0%, #eb9e38 100%);
  background: linear-gradient(to right, #e45d5d 0%, #eb9e38 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.question-wrapper, .instruction-wrapper, .indication-wrapper {
  border-radius: 0.3125vw; }

a {
  text-decoration: none; }
  a:-webkit-any-link {
    text-decoration: none; }

body {
  font-family: "Roboto", sans-serif;
  position: relative;
  overflow-x: hidden; }

.container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px; }

.clipPathBox {
  position: absolute;
  width: 100%;
  right: 0;
  height: 100%; }

.star {
  color: #f17e4a;
  font-size: 1.04167vw; }

.tooltipster-sidetip.tooltipster-light .tooltipster-content {
  font-size: 14px;
  text-align: center; }

.tooltipster-sidetip.tooltipster-light.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #fff; }

.tooltipster-sidetip.tooltipster-light .tooltipster-box {
  background: #fff; }

header .logo {
  padding: 1.40625vw 0 0 3.64583vw; }
  header .logo a {
    font-family: "Roboto", sans-serif;
    font-size: 2.1875vw;
    font-weight: 900;
    color: #834136; }

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100; }

.menu-slim {
  display: none; }

.mobile-menu .nav-mobile {
  display: none; }

.navigation {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 100; }
  .navigation__item {
    display: block;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 2px solid #fd9e38;
    border-radius: 50%;
    margin: 15px 0; }
    .navigation__item.active {
      background-color: #fd9e38;
      border: 2px solid #fd9e38; }
  .navigation.clipPathActive .navigation__item {
    background-color: #fd9e38;
    border: 2px solid white; }
    .navigation.clipPathActive .navigation__item.active {
      background-color: #fff;
      border: 2px solid #fff; }

.overview {
  padding-top: 7.8125vw;
  overflow: hidden; }
  .overview h1 {
    font-family: "Rubik", sans-serif;
    font-size: 3.22917vw;
    font-weight: 500; }
  .overview p {
    font-family: "Roboto", sans-serif;
    font-size: 0.78125vw;
    color: #7d8691;
    width: 80%;
    line-height: 2; }
  .overview .container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    position: relative; }
    .overview .container .left-part {
      padding-bottom: 14.32292vw;
      grid-area: left;
      align-self: center; }
      .overview .container .left-part .button-box {
        margin-top: 4rem; }
        .overview .container .left-part .button-box .btn {
          text-transform: uppercase;
          font-size: 0.72917vw;
          font-weight: 500;
          display: inline-block;
          margin-right: 1.30208vw;
          padding: 0.88542vw 2.86458vw;
          color: #fff;
          border-radius: 50px; }
          .overview .container .left-part .button-box .btn:hover {
            background-position: 100% 50%; }
          .overview .container .left-part .button-box .btn:first-child {
            background-image: linear-gradient(to right, #e45d5d 0%, #fd9e38 100%, #e45d5d 100%);
            background-size: 200% auto;
            box-shadow: 0 7px 12px rgba(231, 101, 88, 0.3);
            top: 0;
            transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, top 0.2s ease-in-out; }
            .overview .container .left-part .button-box .btn:first-child:hover {
              background-position: 100% 50%;
              top: -4px;
              box-shadow: 0 14px 20px rgba(231, 101, 88, 0.3); }
        .overview .container .left-part .button-box .btn--white {
          background: transparent;
          color: #fd9e38;
          padding: 0.78125vw 2.76042vw;
          border: 2px solid #fd9e38;
          position: relative; }
    .overview .container .right-part {
      text-align: center;
      align-self: right;
      position: relative; }
      .overview .container .right-part .desktop-img {
        width: 27.60417vw;
        height: auto;
        z-index: 5; }
        .overview .container .right-part .desktop-img-bg {
          width: 36.77083vw;
          position: absolute;
          left: 50%;
          top: 30%;
          transform: translate(-50%, -50%); }

.question {
  background-image: linear-gradient(to right, #e45d5d, #fd9e38);
  height: 20.83333vw;
  width: 100%;
  position: relative; }
  .question .title {
    font-size: 1.875vw;
    margin: 0;
    font-weight: 300;
    color: #292c35; }
  .question p {
    font-size: 0.9375vw;
    color: #7d8691; }
  .question-wrapper {
    position: relative;
    max-width: 61.19792vw;
    top: 2.60417vw;
    margin: 0 auto;
    background-color: #fff;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    box-shadow: 2px 18px 57px -20px rgba(41, 44, 53, 0.3); }
  .question__left {
    grid-area: left;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 2.08333vw 0 0 1.5625vw; }
    .question__left-bottom {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      padding-top: 3.125vw;
      padding-bottom: 1.04167vw;
      grid-template-areas: 'memory stress dream'; }
      .question__left-bottom p {
        font-size: 0.83333vw;
        color: #292c35; }
      .question__left-bottom sup {
        color: #e76558;
        font-weight: 600;
        cursor: pointer; }
  .question__memory {
    grid-area: memory; }
  .question__dream {
    grid-area: dream; }
  .question__stress {
    grid-area: stress; }
  .question__right {
    grid-area: right;
    justify-self: center;
    align-self: center;
    padding: 1.5625vw 0 1.40625vw 0.78125vw; }
    .question__right img {
      width: 26.5625vw;
      height: 20.10417vw; }
  .question .first {
    position: relative;
    font-family: "Rubik", sans-serif;
    font-weight: 300; }
    .question .first:after {
      content: "";
      position: absolute;
      width: 20.83333vw;
      height: 1px;
      background-color: #efeff2;
      bottom: -1.5625vw;
      left: 0; }

.description {
  background-color: #fafafc; }
  .description .container {
    display: grid;
    grid-template-columns: 52% 48%;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    padding-top: 250px;
    padding-bottom: 100px; }
  .description__left {
    grid-area: left;
    justify-self: center; }
    .description__left img {
      width: 27.60417vw;
      height: auto;
      position: relative;
      z-index: 50; }
  .description__right {
    grid-area: right; }
  .description svg {
    width: 3.90625vw; }
  .description .logo {
    font-size: 1.875vw;
    font-weight: 900;
    color: #834136;
    margin-top: 0; }
  .description .title {
    font-size: 2.39583vw;
    font-weight: 300;
    margin: 0; }
  .description p {
    font-size: 0.88542vw;
    font-weight: 100;
    color: #7d8691;
    margin-bottom: 2.34375vw; }
  .description ul {
    list-style-type: none;
    padding-left: 0; }
  .description li {
    font-size: 0.88542vw;
    font-weight: 100;
    color: #292c35;
    background-image: url(../img/SVG/ico-leaf.svg);
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 2.08333vw;
    margin: 0.78125vw 0; }

.instruction {
  padding-left: 50%;
  padding-bottom: 13.02083vw;
  background-color: #fafafc;
  position: relative;
  z-index: 1; }
  .instruction .title {
    font-size: 2.39583vw;
    font-weight: 300;
    margin: 0; }
  .instruction .heading {
    color: #292c35;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    font-size: 0.9375vw;
    font-weight: 500;
    margin: 1.30208vw 0 1.04167vw; }
  .instruction p {
    font-size: 0.78125vw;
    color: #7d8691;
    margin: 0; }
  .instruction span {
    font-size: 0.9375vw;
    color: #292c35;
    display: inline-block;
    margin: 1.04167vw 0 0.52083vw; }
  .instruction__composition .heading {
    margin: 1.04167vw 0 0; }
  .instruction-wrapper {
    padding: 2.08333vw 2.60417vw 2.60417vw;
    width: 62%;
    box-shadow: 7px 0 19px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
    line-height: 1.7;
    background: #fff; }
  .instruction-rect {
    position: absolute;
    z-index: -1;
    width: 29.79167vw;
    height: 29.16667vw;
    background-image: linear-gradient(45deg, #e76558, #fa963d);
    right: 17.70833vw;
    top: 1.5625vw;
    border-radius: 0.3125vw; }

.indication {
  background-image: linear-gradient(to right, #e45d5d, #fd9e38);
  height: 28.64583vw;
  width: 100%;
  position: relative; }
  .indication div {
    font-size: 1.04167vw;
    color: #292c35; }
  .indication p, .indication ul {
    font-size: 0.78125vw;
    color: #7d8691;
    margin: 0 0 0.52083vw 0;
    line-height: 1.5; }
  .indication svg {
    position: relative;
    transform: translateY(-0.15625vw);
    margin-left: 0.52083vw;
    transition: 0.2s linear all; }
  .indication .title {
    font-size: 2.39583vw;
    font-weight: 300;
    margin: 0 0 1.04167vw 0; }
  .indication .accordion {
    outline: none; }
  .indication-wrapper {
    padding: 2.60417vw 2.60417vw 0vw 2.60417vw;
    background: #fff;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    position: relative;
    z-index: 9;
    transform: translateY(-4.32292vw);
    min-height: 28.64583vw; }
  .indication .open svg {
    transform: rotateZ(-180deg) translateY(0.15625vw);
    margin-left: 0.52083vw; }
  .indication [data-control] {
    font-family: "Rubik", sans-serif;
    padding: 0.78125vw 0;
    cursor: pointer; }
  .indication__left {
    width: 95%; }
    .indication__left p:last-child {
      padding-top: 1.04167vw; }
    .indication__left ul {
      padding-left: 1.04167vw; }
  .indication__right {
    padding-top: 3.38542vw; }
  .indication .line {
    border-bottom: 1px solid #efeff2; }
  .indication .item {
    padding: 0.78125vw 0; }

.usage {
  padding-top: 3.64583vw;
  text-align: center; }
  .usage .title {
    font-size: 2.39583vw;
    font-weight: 300;
    margin-bottom: 0.78125vw; }
  .usage p {
    font-size: 0.83333vw;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 0.78125vw; }
    .usage p:last-child {
      margin-top: 1.04167vw; }
  .usage .star {
    color: #f17e4a;
    font-size: 1.04167vw; }
  .usage span {
    font-size: 0.67708vw;
    color: #7d8691; }

.storage {
  text-align: center; }
  .storage .title {
    font-size: 2.39583vw;
    font-weight: 300;
    margin-bottom: 0.78125vw; }
  .storage p {
    font-size: 0.83333vw;
    width: 47%;
    margin: 0 auto; }
  .storage svg {
    width: 13.02083vw;
    margin-top: 2.60417vw; }
  .storage .btn {
    display: inline-block;
    font-size: 0.9375vw;
    width: 18.22917vw;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    margin: 0 1.5625vw;
    text-transform: uppercase; }
    .storage .btn--drugstore {
      border: 2px solid #FA963D;
      color: #FA963D;
      position: relative;
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
      padding: 0.83333vw 0; }
    .storage .btn--instr {
      padding: 0.9375vw 0;
      color: #fff;
      background-image: linear-gradient(to right, #e45d5d 0%, #fd9e38 100%, #e45d5d 100%);
      background-size: 200% auto;
      box-shadow: 0 10px 20px rgba(231, 101, 88, 0.35);
      top: 0;
      transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, top 0.2s ease-in-out; }
      .storage .btn--instr:hover {
        background-position: 100% 50%;
        top: -4px;
        box-shadow: 0 14px 20px rgba(231, 101, 88, 0.3); }

.btn-box {
  padding: 3.64583vw 0 7.8125vw 0; }

.caution {
  background-image: linear-gradient(to right, #e45d5d, #fd9e38);
  text-align: center;
  margin: 0;
  transform: scaleY(2.5); }
  .caution span {
    font-size: 2.03125vw;
    font-weight: 100;
    color: #fff;
    letter-spacing: -0.05208vw;
    text-transform: uppercase; }

footer .author-wrapper {
  display: flex;
  justify-content: space-between; }
  footer .author-wrapper svg {
    width: 19%;
    padding: 1.5625vw 0.78125vw 0 0; }

footer .author__license {
  padding: 3.90625vw 3.90625vw 1.5625vw 0.26042vw;
  text-align: left; }

footer p {
  color: #656f86;
  font-size: 0.72917vw;
  font-weight: 300;
  margin: 0.10417vw; }

footer .author__copyright .container {
  display: flex;
  justify-content: space-between;
  font-size: 0.72917vw;
  padding: 0.72917vw;
  font-weight: 300;
  position: relative;
  overflow: hidden; }
  footer .author__copyright .container a {
    position: relative;
    font-weight: 500;
    color: #f17e4a; }
    footer .author__copyright .container a:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -0.15625vw;
      height: 2px;
      width: 0;
      background-color: #f17e4a;
      transition: width 0.4s ease-in-out; }
    footer .author__copyright .container a:hover:after {
      left: 0;
      width: 100%; }
  footer .author__copyright .container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #deecf6; }

@media screen and (max-width: 756px) {
  .mobile-menu .nav-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
    position: fixed;
    margin-left: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100%;
    width: 100%;
    background-color: #fff; }
    .mobile-menu .nav-mobile a {
      color: #E76558;
      margin: 2rem 0;
      font-size: 6.77083vw;
      position: relative;
      transition: opacity 0.3s linear;
      transform: translateX(-600%); }
      .mobile-menu .nav-mobile a:hover {
        opacity: 0.7; }
      .mobile-menu .nav-mobile a:after {
        content: '';
        position: absolute;
        bottom: -1em;
        left: 50%;
        transform: translateX(-50%);
        width: 2em;
        height: 0.1em;
        background-color: #E76558; }
      .mobile-menu .nav-mobile a:last-child:after {
        display: none; } }

@media screen and (max-width: 756px) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: white;
    box-shadow: 0 2px 5px 0 rgba(231, 101, 88, 0.1); }
    header .logo {
      padding: 1rem 0 1rem 2rem; }
      header .logo a {
        font-size: 7.8125vw; }
  .menu-slim {
    display: block;
    background: transparent;
    position: absolute;
    z-index: 555;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    height: 17px;
    width: 50px;
    cursor: pointer; }
    .menu-slim:before, .menu-slim:after {
      background: #E76558;
      backface-visibility: hidden;
      content: "";
      height: 3px;
      left: 0;
      transition: 0.75s;
      width: 30px; }
    .menu-slim:before {
      box-shadow: #E76558 0 7px 0 0;
      position: absolute;
      top: 0; }
    .menu-slim:after {
      position: absolute;
      top: calc(100% - 3px); }
  .menu-js-toggle:before {
    box-shadow: transparent 0 0 0 0;
    top: 50%;
    transform: rotate(225deg); }
  .menu-js-toggle:after {
    top: 50%;
    transform: rotate(315deg); } }

@media screen and (max-width: 756px) {
  .navigation {
    display: none; } }

@media screen and (max-width: 756px) {
  .overview {
    text-align: center;
    padding-top: 26.04167vw; }
    .overview .container {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: 'left' 'right'; }
      .overview .container .left-part .button-box {
        margin-top: 13.02083vw; }
        .overview .container .left-part .button-box .btn {
          width: 78.125vw;
          font-size: 4.16667vw;
          padding: 3.90625vw 0; }
          .overview .container .left-part .button-box .btn--white {
            margin-top: 7.8125vw; }
      .overview .container .right-part {
        padding: 18.22917vw 0; }
        .overview .container .right-part .desktop-img-bg {
          width: 88.54167vw;
          top: 45%; }
        .overview .container .right-part .desktop-img {
          width: 67.70833vw; }
    .overview p {
      width: 100%;
      font-size: 4.16667vw; }
    .overview h1 {
      font-size: 10.22917vw;
      padding-bottom: 15.625vw; } }

@media screen and (max-width: 756px) {
  .question {
    height: 234.375vw;
    text-align: center; }
    .question .first:after {
      width: 98%;
      bottom: -7.5625vw; }
    .question-wrapper {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: 'left' 'right';
      border-radius: 5px;
      top: -10vw;
      max-width: 90.19792vw; }
    .question__left {
      grid-row-gap: 7.8125vw; }
      .question__left-bottom {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-row-gap: 7.8125vw;
        grid-template-areas: 'memory' 'dream' 'stress'; }
    .question__right {
      padding: 6.25vw 0; }
      .question__right img {
        width: 78.125vw;
        height: 61.10417vw; }
    .question .title {
      font-size: 6.25vw; }
    .question p {
      font-size: 4.16667vw; } }

@media screen and (max-width: 756px) {
  .description .container {
    grid-template-columns: 1fr;
    grid-template-areas: 'right';
    padding: 26.04167vw 20px 10.41667vw; }
  .description__left {
    display: none; }
  .description__right svg {
    width: 41.66667vw; }
  .description__right .logo {
    font-size: 10.41667vw; }
  .description__right .title {
    font-size: 7.8125vw; }
  .description__right p {
    font-size: 5.20833vw;
    padding: 20px 0; }
  .description__right ul {
    margin: 30px 0; }
  .description__right li {
    font-size: 4.16667vw;
    padding-left: 10.41667vw;
    margin: 6.25vw 0; } }

@media screen and (max-width: 756px) {
  .instruction {
    padding-left: 0;
    padding-bottom: 0; }
    .instruction-wrapper {
      width: 98%;
      border-radius: 5px; }
    .instruction .title {
      font-size: 7.8125vw; }
    .instruction .heading, .instruction span {
      font-size: 5.20833vw; }
    .instruction p {
      font-size: 4.16667vw; }
    .instruction-rect {
      width: 88.54167vw;
      height: 152.58333vw;
      right: 1.5625vw;
      top: 5.20833vw;
      border-radius: 5px; }
    .instruction__form {
      margin: 35px 0 20px; }
    .instruction__composition {
      margin: 35px 0 20px; }
      .instruction__composition .heading {
        margin-bottom: 15px; }
      .instruction__composition span {
        margin-bottom: 10px; }
    .instruction__supplementary {
      margin: 35px 0 20px; } }

@media screen and (max-width: 756px) {
  .indication {
    height: 215vw; }
    .indication .container {
      padding: 0 5.20833vw; }
    .indication-wrapper {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: 'left' 'right';
      border-radius: 5px; }
    .indication .title {
      font-size: 7.8125vw; }
    .indication [data-control], .indication .item {
      font-size: 4.16667vw;
      margin: 15px 0;
      position: relative; }
      .indication [data-control] svg, .indication .item svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
    .indication [data-content] {
      font-weight: 100; }
    .indication .open svg {
      transform: rotateX(180deg) translateY(0.15625vw);
      margin-left: 0.52083vw; }
    .indication ul {
      padding-left: 6.04167vw; }
    .indication p, .indication li {
      font-size: 4.16667vw; }
    .indication .star {
      font-size: 5.20833vw; } }

@media screen and (max-width: 756px) {
  .usage {
    padding-top: 13.02083vw; }
    .usage .title {
      font-size: 7.8125vw;
      margin-bottom: 15px; }
    .usage p {
      padding-bottom: 15px; }
      .usage p:last-child {
        margin-top: 20px; }
    .usage p, .usage span {
      width: 80%;
      font-size: 4.16667vw; }
    .usage .star {
      font-size: 4.04167vw; } }

@media screen and (max-width: 756px) {
  .storage .title {
    font-size: 7.8125vw;
    margin-bottom: 15px; }
  .storage p {
    width: 80%;
    font-size: 4.16667vw; }
  .storage .btn {
    width: 78.125vw;
    font-size: 4.16667vw;
    padding: 3.90625vw 0; }
    .storage .btn--instr {
      margin: 7.8125vw;
      padding: 4.16667vw 0; } }

@media screen and (max-width: 756px) {
  .caution {
    margin: 13.02083vw 0; }
    .caution span {
      font-size: 5.20833vw; } }

@media screen and (max-width: 756px) {
  footer .author-wrapper {
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
    footer .author-wrapper svg {
      width: 52.08333vw;
      padding: 7.8125vw 0; }
  footer .author__license {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 13.02083vw;
    order: 2; }
    footer .author__license p {
      font-size: 3.64583vw; }
  footer .author__copyright .container {
    font-size: 12px;
    padding: 15px; } }
