.navigation {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 100;
  &__item {
    display: block;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 2px solid #fd9e38;
    border-radius: 50%;
    margin: 15px 0;
    &.active {
      background-color: #fd9e38;
      border: 2px solid #fd9e38; } }

  &.clipPathActive {
    .navigation__item {
      background-color: #fd9e38;
      border: 2px solid white;
      &.active {
        background-color: #fff;
        border: 2px solid #fff; } } } }




