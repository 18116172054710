.storage {
  text-align: center;
  .title {
      font-size: get-vw(46px);
      font-weight: 300;
      margin-bottom: get-vw(15px);
      @extend %txtGrad; }
  p {
    font-size: get-vw(16px);
    width: 47%;
    margin: 0 auto; }
  svg {
    width: get-vw(250px);
    margin-top: get-vw(50px); }
  .btn {
    display: inline-block;
    font-size: get-vw(18px);
    width: get-vw(350px);
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    margin: 0 get-vw(30px);
    @extend %relative;
    text-transform: uppercase;


    &--drugstore {
      border: 2px solid #FA963D;
      color: #FA963D;
      position: relative;
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
      padding: get-vw(16px) 0; }

    &--instr {
      @extend %relative;
      padding: get-vw(18px) 0;
      color: #fff;
      background-image: linear-gradient(to right, #e45d5d 0%, #fd9e38 100%, #e45d5d 100%);
      background-size: 200% auto;
      box-shadow: 0 10px 20px rgba(231,101,88,.35);
      top: 0;
      transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, top 0.2s ease-in-out;
      &:hover {
        background-position: 100% 50%;
        top: -4px;
        box-shadow: 0 14px 20px rgba(231,101,88,.3); } } } }
.btn-box {
  padding: get-vw(70px) 0 get-vw(150px) 0; }




