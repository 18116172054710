header {
    .logo {
        padding: get-vw(27px) 0 0 get-vw(70px);
        a {
            font-family: $roboto;
            font-size: get-vw(42px);
            font-weight: 900;
            color: #834136; } } }

.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; }

.menu-slim {
    display: none; }


.mobile-menu {
    .nav-mobile {
        display: none; } }
