$roboto: 'Roboto', sans-serif;
$rubik: 'Rubik', sans-serif;




@function get-vw($object) {
  $vw: (1920 * 0.01) * 1px;
  @return ($object / $vw) * 1vw; }
// placeholders


%transition {
  transition: 0.2s linear; }

// extends
%vam {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

%ham {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

%relative {
  position: relative; }

%txtGrad {
  background: rgb(228,93,93);
  background: -moz-linear-gradient(left, rgba(228,93,93,1) 0%,rgba(235,158,56,1) 100%);
  background: -webkit-linear-gradient(left, rgba(228,93,93,1) 0%,rgba(235,158,56,1) 100%);
  background: linear-gradient(to right, rgba(228,93,93,1) 0%,rgba(235,158,56,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

%bordRadius {
  border-radius: get-vw(6px); }
