.overview {
    padding-top: get-vw(150px);
    overflow: hidden;
    h1 {
        @extend %txtGrad;
        font-family: $rubik;
        font-size: get-vw(62px);
        font-weight: 500; }
    p {
        font-family: $roboto;
        font-size: get-vw(15px);
        color: #7d8691;
        width: 80%;
        line-height: 2; }
    .container {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas: 'left right';
        position: relative;
        .left-part {
            padding-bottom: get-vw(275px);
            grid-area: left;
            align-self: center;
            .button-box {
                margin-top: 4rem;
                .btn {
                    text-transform: uppercase;
                    font-size: get-vw(14px);
                    font-weight: 500;
                    display: inline-block;
                    margin-right: get-vw(25px);
                    padding: get-vw(17px) get-vw(55px);
                    color: #fff;
                    border-radius: 50px;
                    &:hover {
                        background-position: 100% 50%; }
                    &:first-child {
                        @extend %relative;
                        background-image: linear-gradient(to right, #e45d5d 0%, #fd9e38 100%, #e45d5d 100%);
                        background-size: 200% auto;
                        box-shadow: 0 7px 12px rgba(231,101,88,.3);
                        top: 0;
                        transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, top 0.2s ease-in-out;
                        &:hover {
                            background-position: 100% 50%;
                            top: -4px;
                            box-shadow: 0 14px 20px rgba(231,101,88,.3); } } }

                .btn--white {
                    background: transparent;
                    color: #fd9e38;
                    padding: get-vw(15px) get-vw(53px);
                    border: 2px solid #fd9e38;
                    position: relative; } } }

        .right-part {
            text-align: center;
            align-self: right;
            position: relative;
            .desktop-img {
                width: get-vw(530px);
                height: auto;
                @extend %relative;
                z-index: 5;
                &-bg {
                    width: get-vw(706px);
                    position: absolute;
                    left: 50%;
                    top: 30%;
                    transform: translate(-50%, -50%); } } } } }
