@media screen and (max-width: 756px) {
  .mobile-menu {
    .nav-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 999;
      position: fixed;
      margin-left: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 100%;
      width: 100%;
      background-color:  #fff;
      a {
        color: #E76558;
        margin: 2rem 0;
        font-size: get-vw(130px);
        position: relative;
        transition: opacity 0.3s linear;
        transform: translateX(-600%);
        &:hover {
          opacity: 0.7; }
        &:after {
          content: '';
          position: absolute;
          bottom: -1em;
          left: 50%;
          transform: translateX(-50%);
          width: 2em;
          height: 0.1em;
          background-color: #E76558; }
        &:last-child:after {
          display: none; } } } } }





