@media screen and (max-width: 756px) {
    .instruction {
        padding-left: 0;
        padding-bottom: 0;
        &-wrapper {
            width: 98%;
            border-radius: 5px; }
        .title {
            font-size: get-vw(150px); }
        .heading, span {
            font-size: get-vw(100px); }
        p {
            font-size: get-vw(80px); }
        &-rect {
            width: get-vw(1700px);
            height: 152.58333vw;
            right: get-vw(30px);
            top: get-vw(100px);
            border-radius: 5px; }
        &__form {
            margin: 35px 0 20px; }
        &__composition {
            margin: 35px 0 20px;
            .heading {
                margin-bottom: 15px; }
            span {
                margin-bottom: 10px; } }
        &__supplementary {
            margin: 35px 0 20px; } } }
