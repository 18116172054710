@media screen and (max-width: 756px) {
    .storage {
        .title {
            font-size: get-vw(150px);
            margin-bottom: 15px; }
        p {
            width: 80%;
            font-size: get-vw(80px); }
        .btn {
            width: get-vw(1500px);
            font-size: get-vw(80px);
            padding: get-vw(75px) 0;
            &--instr {
                margin: get-vw(150px);
                padding: get-vw(80px) 0; } } } }
