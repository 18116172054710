.caution {
  background-image: linear-gradient(to right, #e45d5d, #fd9e38);
  text-align: center;
  margin: 0;
  transform: scaleY(2.5);
  span {
    font-size: get-vw(39px);
    font-weight: 100;
    color: #fff;
    letter-spacing: get-vw(-1px);
    text-transform: uppercase; } }



