@media screen and (max-width: 756px) {
    .indication {
        height: 215vw;
        .container {
            padding: 0 get-vw(100px); }
        &-wrapper {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            grid-template-areas: 'left' 'right';
            border-radius: 5px; }
        .title {
            font-size: get-vw(150px); }
        [data-control], .item {
            font-size: get-vw(80px);
            margin: 15px 0;
            position: relative;
            svg {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%); } }
        [data-content] {
            font-weight: 100; }
        .open {
            svg {
                transform: rotateX(180deg) translateY(get-vw(3px));
                margin-left: get-vw(10px); } }
        ul {
            padding-left: 6.04167vw; }

        p, li {
            font-size: get-vw(80px); }
        .star {
            font-size: get-vw(100px); } } }

