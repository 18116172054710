.usage {
    padding-top: get-vw(70px);
    text-align: center;
    .title {
        font-size: get-vw(46px);
        font-weight: 300;
        margin-bottom: get-vw(15px);
        @extend %txtGrad; }
    p {
        font-size: get-vw(16px);
        width: 60%;
        margin: 0 auto;
        padding-bottom: get-vw(15px);
        &:last-child {
            margin-top: get-vw(20px); } }
    .star {
        color: #f17e4a;
        font-size: get-vw(20px); }
    span {
        font-size: get-vw(13px);
        color: #7d8691; } }
