@media screen and (max-width: 756px) {
    header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background: white;
        box-shadow: 0 2px 5px 0 rgba(231,101,88,.1);
        .logo {
            padding: 1rem 0 1rem 2rem;
            a {
                font-size: get-vw(150px); } } }

    //Menu burger button
    .menu-slim {
        display: block;
        background: transparent;
        position: absolute;
        z-index: 555;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        height: 17px;
        width: 50px;
        cursor: pointer;
        &:before,
        &:after {
            background: #E76558;
            backface-visibility: hidden;
            content: "";
            height: 3px;
            left: 0;
            transition: 0.75s;
            width: 30px; }


        &:before {
            box-shadow: #E76558 0 7px 0 0;
            position: absolute;
            top: 0; }


        &:after {
            position: absolute;
            top: calc(100% - 3px); } }


    .menu-js-toggle {
        &:before {
            box-shadow: transparent 0 0 0 0;
            top: 50%;
            transform: rotate(225deg); }

        &:after {
            top: 50%;
            transform: rotate(315deg); } } }
