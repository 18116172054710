
@media screen and (max-width: 756px) {
    footer {
        .author-wrapper {
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            svg {
                width: get-vw(1000px);
                padding: get-vw(150px) 0; } }
        .author__license {
            text-align: center;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: get-vw(250px);
            order: 2;

            p {
                font-size: get-vw(70px); } }
        .author__copyright {
            .container {
                font-size: 12px;
                padding: 15px; } } } }
