.instruction {
    padding-left: 50%;
    padding-bottom: get-vw(250px);
    background-color: #fafafc;
    position: relative;
    z-index: 1;
    .title {
        font-size: get-vw(46px);
        font-weight: 300;
        margin: 0;
        @extend %txtGrad; }
    .heading {
        color: #292c35;
        text-transform: uppercase;
        font-family: $rubik;
        font-size: get-vw(18px);
        font-weight: 500;
        margin: get-vw(25px) 0 get-vw(20px); }
    p {
        font-size: get-vw(15px);
        color: #7d8691;
        margin: 0; }
    span {
        font-size: get-vw(18px);
        color: #292c35;
        display: inline-block;
        margin: get-vw(20px) 0 get-vw(10px); }
    &__composition {
        .heading {
            margin: get-vw(20px) 0 0; } }
    &-wrapper {
        padding: get-vw(40px) get-vw(50px) get-vw(50px);
        width: 62%;
        box-shadow: 7px 0 19px rgba(0,0,0,0.1);
        margin-bottom: 100px;
        line-height: 1.7;
        background: #fff;
        @extend %bordRadius;
        @extend %relative; }
    &-rect {
        position: absolute;
        z-index: -1;
        width: get-vw(572px);
        height: get-vw(560px);
        background-image: linear-gradient(45deg,#e76558,#fa963d);
        right: get-vw(340px);
        top: get-vw(30px);
        border-radius: get-vw(6px); } }

