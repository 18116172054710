.indication {
    background-image: linear-gradient(to right, #e45d5d, #fd9e38);
    height: get-vw(550px);
    width: 100%;
    position: relative;

    div {
        font-size: get-vw(20px);
        color: #292c35; }
    p, ul {
        font-size: get-vw(15px);
        color: #7d8691;
        margin: 0 0 get-vw(10px) 0;
        line-height: 1.5; }

    svg {
        position: relative;
        transform: translateY(get-vw(-3px));
        margin-left: get-vw(10px);
        transition: 0.2s linear all; }

    .title {
        font-size: get-vw(46px);
        font-weight: 300;
        margin: 0 0 get-vw(20px) 0;
        @extend %txtGrad; }
    .accordion {
        outline: none; }
    &-wrapper {
        padding: get-vw(50px) get-vw(50px) get-vw(0px) get-vw(50px);
        background: #fff;
        @extend %bordRadius;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas: 'left right';
        position: relative;
        z-index: 9;
        transform: translateY(get-vw(-83px));
        min-height: get-vw(550px); }

    .open {
        svg {
            transform: rotateZ(-180deg) translateY(get-vw(3px));
            margin-left: get-vw(10px); } }
    [data-control] {
        font-family: $rubik;
        padding: get-vw(15px) 0;
        cursor: pointer; }
    &__left {
        width: 95%;
        p:last-child {
            padding-top: get-vw(20px); }
        ul {
            padding-left: get-vw(20px); } }
    &__right {
        padding-top: get-vw(65px); }
    .line {
        border-bottom: 1px solid #efeff2; }
    .item {
        padding: get-vw(15px) 0; } }


