footer {
  .author-wrapper {
    display: flex;
    justify-content: space-between;
    svg {
      width: 19%;
      padding: get-vw(30px) get-vw(15px) 0 0; } }

  .author__license {
    padding: get-vw(75px) get-vw(75px) get-vw(30px) get-vw(5px);
    text-align: left; }
  p {
    color: #656f86;
    font-size: get-vw(14px);
    font-weight: 300;
    margin: get-vw(2px); }
  .author__copyright {
    .container {
      display: flex;
      justify-content: space-between;
      font-size: get-vw(14px);
      padding: get-vw(14px);
      font-weight: 300;
      position: relative;
      overflow: hidden;
      a {
        position: relative;
        font-weight: 500;
        color: #f17e4a;
        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: get-vw(-3px);
          height: 2px;
          width: 0;
          background-color: #f17e4a;
          transition: width 0.4s ease-in-out; }
        &:hover {
          &:after {
            left: 0;
            width: 100%; } } }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #deecf6; } } } }



