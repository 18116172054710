@media screen and (max-width: 756px) {
    .overview {
        text-align: center;
        padding-top: get-vw(500px);
        .container {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            grid-template-areas: 'left' 'right';
            .left-part {
                .button-box {
                    margin-top: get-vw(250px);
                    .btn {
                        width: get-vw(1500px);
                        font-size: get-vw(80px);
                        padding: get-vw(75px) 0;
                        &--white {
                            margin-top: get-vw(150px); } } } }
            .right-part {
                padding: get-vw(350px) 0;
                .desktop-img-bg {
                    width: get-vw(1700px);
                    top: 45%; }
                .desktop-img {
                    width: get-vw(1300px); } } }
        p {
            width: 100%;
            font-size: get-vw(80px); }
        h1 {
            font-size: 10.22917vw;
            padding-bottom: get-vw(300px); } } }


