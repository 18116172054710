.question {
    background-image: linear-gradient(to right, #e45d5d, #fd9e38);
    height: get-vw(400px);
    width: 100%;
    position: relative;
    .title {
        font-size: get-vw(36px);
        margin: 0;
        font-weight: 300;
        color: #292c35; }
    p {
        font-size: get-vw(18px);
        color: #7d8691; }

    &-wrapper {
        position: relative;
        max-width: get-vw(1175px);
        top: get-vw(50px);
        margin: 0 auto;
        background-color: #fff;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas: 'left right';
        @extend %bordRadius;
        box-shadow: 2px 18px 57px -20px rgba(41,44,53,0.3); }
    &__left {
        grid-area: left;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        padding: get-vw(40px) 0 0 get-vw(30px);
        &-bottom {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            padding-top: get-vw(60px);
            padding-bottom: get-vw(20px);
            grid-template-areas: 'memory stress dream';
            p {
                font-size: get-vw(16px);
                color: #292c35; }
            sup {
                color: #e76558;
                font-weight: 600;
                cursor: pointer; } } }
    &__memory {
        grid-area: memory; }
    &__dream {
        grid-area: dream; }
    &__stress {
        grid-area: stress; }

    &__right {
        grid-area: right;
        justify-self: center;
        align-self: center;
        padding: get-vw(30px) 0 get-vw(27px) get-vw(15px);
        img {
            width: get-vw(510px);
            height: get-vw(386px); } }
    .first {
        position: relative;
        font-family: $rubik;
        font-weight: 300;
        &:after {
            content: "";
            position: absolute;
            width: get-vw(400px);
            height: 1px;
            background-color: #efeff2;
            bottom: get-vw(-30px);
            left: 0; } } }





