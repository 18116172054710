@media screen and (max-width: 756px) {
    .question {
        height: get-vw(4500px);
        text-align: center;
        .first:after {
            width: 98%;
            bottom: -7.5625vw; }
        &-wrapper {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            grid-template-areas: 'left' 'right';
            border-radius: 5px;
            top: -10vw;
            max-width: 90.19792vw; }
        &__left {
            grid-row-gap: get-vw(150px);
            &-bottom {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto auto;
                grid-row-gap: get-vw(150px);
                grid-template-areas: 'memory' 'dream' 'stress'; } }
        &__right {

            padding: get-vw(120px) 0;
            img {
                width: get-vw(1500px);
                height: 61.10417vw; } }
        .title {
            font-size: get-vw(120px); }
        p {
            font-size: get-vw(80px); } } }

