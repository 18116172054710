a {
    text-decoration: none;
    &:-webkit-any-link {
        text-decoration: none; } }

body {
    font-family: $roboto;
    position: relative;
    overflow-x: hidden; }
.container {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 15px; }
.clipPathBox {
    position: absolute;
    width: 100%;
    right: 0;
    height: 100%; }

.star {
    color: #f17e4a;
    font-size: get-vw(20px); }

.tooltipster-sidetip.tooltipster-light .tooltipster-content {
    font-size: 14px;
    text-align: center; }

.tooltipster-sidetip.tooltipster-light.tooltipster-top .tooltipster-arrow-background {
    border-top-color: #fff; }

.tooltipster-sidetip.tooltipster-light .tooltipster-box {
    background: #fff; }

@function get-vw($object) {
    $vw: (1920 * 0.01) * 1px;
    @return ($object / $vw) * 1vw; }
