@media screen and (max-width: 756px) {
    .description {
        .container {
            grid-template-columns: 1fr;
            grid-template-areas: 'right';
            padding: get-vw(500px) 20px get-vw(200px); }
        &__left {
            display: none; }
        &__right {
            svg {
                width: get-vw(800px); }
            .logo {
                font-size: get-vw(200px); }
            .title {
                font-size: get-vw(150px); }
            p {
                font-size: get-vw(100px);
                padding: 20px 0; }
            ul {
                margin: 30px 0; }
            li {
                font-size: get-vw(80px);
                padding-left: get-vw(200px);
                margin: get-vw(120px) 0; } } } }

