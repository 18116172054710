.description {
    background-color: #fafafc;
    .container {
        display: grid;
        grid-template-columns: 52% 48%;
        grid-template-rows: auto;
        grid-template-areas: 'left right';
        padding-top: 250px;
        padding-bottom: 100px; }
    &__left {
        grid-area: left;
        justify-self: center;
        img {
            width: get-vw(530px);
            height: auto;
            position: relative;
            z-index: 50; } }
    &__right {
        grid-area: right; }
    svg {
        width: get-vw(75px); }
    .logo {
        font-size: get-vw(36px);
        font-weight: 900;
        color: #834136;
        margin-top: 0; }
    .title {
        font-size: get-vw(46px);
        font-weight: 300;
        margin: 0;
        @extend %txtGrad; }
    p {
        font-size: get-vw(17px);
        font-weight: 100;
        color: #7d8691;
        margin-bottom: get-vw(45px); }
    ul {
        list-style-type: none;
        padding-left: 0; }
    li {
        font-size: get-vw(17px);
        font-weight: 100;
        color: #292c35;
        background-image: url(../img/SVG/ico-leaf.svg);
        background-repeat: no-repeat;
        background-size: contain;
        padding-left: get-vw(40px);
        margin: get-vw(15px) 0;
        @extend %relative; } }
